.site-layout {
    background: #000;
    // background: #111 url("../../assets/rockywall-inv.jpg") scroll;
    color: #fff;
}

.page-main{

  position: relative;
//   &:before{
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100px;
//     width: 100%;
//     // background: url("../../assets/footer-top.png") repeat-x;
//     transform: rotate(-180deg);
//     opacity: .5;
//   }
}

.section {
    position: relative;
    overflow: hidden;
    padding: 60px 0 0;
    @media (min-width: 360px) {
        padding: 90px 0 0;
    }
    @media (min-width: 768px) {
        padding: 120px 0 0;
    }
    @media (min-width: 1024px) {
        padding: 135px 0 0;
    }
    @media (min-width: 1200px) {
        padding: 150px 0 0;
    }
    header {
        margin-bottom: 50px;
        h2 {
            margin: 0;
            padding: 0;
            line-height: 100%;
            text-transform: uppercase;
            font-size: 50px;
            @media (min-width: 360px) {
                font-size: 80px;
            }
            @media (min-width: 768px) {
                font-size: 120px;
            }
        }
    }
}
