@import "~bootstrap/scss/bootstrap-reboot.scss";
@import url("https://use.typekit.net/ead5cey.css");
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Waiting+for+the+Sunrise&display=swap');

$yellow: #f3ec9e;
$orange: #ff8242;
$grey: #ccc;
$white: #f4f0f1;

html{
  height: 100%;
  background-color: #000;
  color: #fff;
}
body{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Anton', sans-serif;
  // color: black;
}

#root{
  height: 100%;
}

a{
  color: inherit;
  &:hover{
    color: inherit
  }
}

blockquote,
h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
  padding: 0;
}

p{
  margin-bottom: 1em;
}

em {
  font-style: normal;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

.svgsprite {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}

.app{
  height: 100%;
  background-color: #fff;
}

.view{
  height: 100%;
  background: #fff;
}

.site-layout{
  overflow: hidden;
  outline: none;
  position: relative;
  height: 100%;
  z-index: 1;

  .scroll-content{
    height: 100%;
    will-change: transform;
    overflow-x: scroll;
  }
}

.container{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  @media(min-width: 768px) {
    width: 600px;
  }

  @media(min-width: 1024px) {
    width: 820px;
  }

  @media(min-width: 1200px) {
    width: 980px;
  }
}

.content {
  padding: 120px 0;
  @media (min-width: 360px) {
    padding: 170px 0;
  }

  @media (min-width: 768px) {
    padding: 300px 0;
  }
}

.-color-project-1 {
  color: red;
}

.-color-project-2 {
  color: orange;
}

.-color-project-3 {
  color: green;
}